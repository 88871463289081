export const Files = [
'https://app.staging.gogobrain.com/assets/common/img/answers1.gif',
'https://app.staging.gogobrain.com/assets/common/img/answers2.gif',
'https://app.staging.gogobrain.com/assets/common/img/arrows.png',
'https://app.staging.gogobrain.com/assets/common/img/blank.png',
'https://app.staging.gogobrain.com/assets/common/img/chat-bubble.png',
'https://app.staging.gogobrain.com/assets/common/img/checks.png',
'https://app.staging.gogobrain.com/assets/common/img/go-back-assessment.png',
'https://app.staging.gogobrain.com/assets/common/img/icons-black.png',
'https://app.staging.gogobrain.com/assets/common/img/icons.png',
'https://app.staging.gogobrain.com/assets/common/img/loading.gif',
'https://app.staging.gogobrain.com/assets/common/img/notification-bar.png',
'https://app.staging.gogobrain.com/assets/common/img/replay-audio.png',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-large/character10.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-large/character14_sad.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-large/character16_sad.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-large/character18.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-large/character6_sad.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-large/character9.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character1.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character10.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character11.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character12.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character13.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character14.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character15.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character16.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character17.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character18.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character19.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character2.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character20.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character3.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character4.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character5.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character6.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character7.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character8.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/character9.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-medium/overstock_logo2.jpg',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/blank.png',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character1.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character10.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character11.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character12.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character13.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character14.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character15.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character16.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character17.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character18.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character19.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character2.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character20.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character3.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character4.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character5.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character6.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character7.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character8.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/animations-small/character9.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character1.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character10.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character11.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character12.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character13.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character14.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character15.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character17.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character18.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character19.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character2.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character20.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character3.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character4.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character5.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character6.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character7.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character8.gif',
'https://app.staging.gogobrain.com/assets/common/img/spacebabies/character9.gif',
'https://app.staging.gogobrain.com/assets/common/img/stars.png',
'https://app.staging.gogobrain.com/assets/common/img/volume-sprite.png',
'https://app.staging.gogobrain.com/assets/common/img/watch-tutorial.png',
'https://app.staging.gogobrain.com/assets/home/child1.gif',
'https://app.staging.gogobrain.com/assets/home/child2.gif',
'https://app.staging.gogobrain.com/assets/home/child3.gif',
'https://app.staging.gogobrain.com/assets/home/child4.gif',
'https://app.staging.gogobrain.com/assets/home/child5.gif',
'https://app.staging.gogobrain.com/assets/home/child6.gif',
'https://app.staging.gogobrain.com/assets/home/flying-following-directions-off.png',
'https://app.staging.gogobrain.com/assets/home/flying-following-directions-on.png',
'https://app.staging.gogobrain.com/assets/home/hocus-pocus-off.png',
'https://app.staging.gogobrain.com/assets/home/hocus-pocus-on.png',
'https://app.staging.gogobrain.com/assets/home/home-background.jpg',
'https://app.staging.gogobrain.com/assets/home/listening-launch-off.png',
'https://app.staging.gogobrain.com/assets/home/listening-launch-on.png',
'https://app.staging.gogobrain.com/assets/home/milky-way-memory-off.png',
'https://app.staging.gogobrain.com/assets/home/milky-way-memory-on.png',
'https://app.staging.gogobrain.com/assets/home/npc-talking.gif',
'https://app.staging.gogobrain.com/assets/home/npc.gif',
'https://app.staging.gogobrain.com/assets/home/planet-pattern-puzzler-off.png',
'https://app.staging.gogobrain.com/assets/home/planet-pattern-puzzler-on.png',
'https://app.staging.gogobrain.com/assets/home/self-control-off.png',
'https://app.staging.gogobrain.com/assets/home/self-control-on.png',
'https://app.staging.gogobrain.com/assets/home/space-baby.gif',
'https://app.staging.gogobrain.com/assets/home/spaceship1-off.png',
'https://app.staging.gogobrain.com/assets/home/spaceship1-on.png',
'https://app.staging.gogobrain.com/assets/home/spaceship2-off.png',
'https://app.staging.gogobrain.com/assets/home/spaceship2-on.png',
'https://app.staging.gogobrain.com/assets/home/spaceship3-off.png',
'https://app.staging.gogobrain.com/assets/home/spaceship3-on.png',
'https://app.staging.gogobrain.com/assets/home/thinking-universe-off.png',
'https://app.staging.gogobrain.com/assets/home/thinking-universe-on.png',
'https://app.staging.gogobrain.com/assets/achievements/achievement-box-gems.jpg',
'https://app.staging.gogobrain.com/assets/achievements/achievement-box-off.jpg',
'https://app.staging.gogobrain.com/assets/achievements/achievement-box.jpg',
'https://app.staging.gogobrain.com/assets/achievements/bronze-medal.png',
'https://app.staging.gogobrain.com/assets/achievements/burst.png',
'https://app.staging.gogobrain.com/assets/achievements/cup.png',
'https://app.staging.gogobrain.com/assets/achievements/gold-medal.png',
'https://app.staging.gogobrain.com/assets/achievements/icons-yellow.png',
'https://app.staging.gogobrain.com/assets/achievements/icons.png',
'https://app.staging.gogobrain.com/assets/achievements/img/back-button.png',
'https://app.staging.gogobrain.com/assets/achievements/plaque.png',
'https://app.staging.gogobrain.com/assets/achievements/question.png',
'https://app.staging.gogobrain.com/assets/achievements/ribbon.png',
'https://app.staging.gogobrain.com/assets/achievements/silver-medal.png',
'https://app.staging.gogobrain.com/assets/achievements/totalQuestions.gif',
];
